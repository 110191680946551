<template>
  <CRow>
    <CModal
        title="View Event"
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        :hide-footer="true"
        color="primary"
    >

      <CCardBody>
        <CCol md="12" class="align-self-center">
          <CRow>
            <CCol sm="6" md="4" lg="4">
              <p class="custome-labal">
                Title :
              </p>
              <p class="custome-detail">
                {{ self.event.title }}
              </p>
            </CCol>

            <CCol sm="6" md="4" lg="4">
              <p class="custome-labal">
                Description :
              </p>
              <p class="custome-detail">
                {{ self.event.description }}
              </p>
            </CCol>


            <CCol sm="6" md="4" lg="4" v-if="self.event.link">
              <p class="custome-labal">
                Link :
              </p>
              <p class="custome-detail">
                {{ self.event.link }}
              </p>
            </CCol>

            <CCol sm="6" md="4" lg="4" v-if="self.event.location">
              <p class="custome-labal">
                Location :
              </p>
              <p class="custome-detail">
                {{ self.event.location }}
              </p>
            </CCol>


            <CCol sm="6" md="4" lg="4" v-if="self.event.participants">
              <p class="custome-labal">
                Participants :
              </p>
              <p class="custome-detail">
                {{ self.event.participants }}
              </p>
            </CCol>

            <CCol sm="6" md="4" lg="4">
              <p class="custome-labal">
                isFree? :
              </p>
              <p class="custome-detail">
                <CBadge :color="statusBudget(self.event.isFree)">{{ self.event.isFree }}</CBadge>
              </p>
            </CCol>

            <CCol sm="6" md="4" lg="4">
              <p class="custome-labal">
                isOnline? :
              </p>
              <p class="custome-detail">

                <CBadge :color="statusBudget(self.event.isOnline)">{{ self.event.isOnline }}}</CBadge>
              </p>
            </CCol>

            <CCol sm="6" md="4" lg="4">
              <p class="custome-labal">
                Start Time :
              </p>
              <p class="custome-detail">
                {{ self.event.startTime }}
              </p>
            </CCol>
            <CCol sm="6" md="4" lg="4">
              <p class="custome-labal">
                End Time :
              </p>
              <p class="custome-detail">
                {{ self.event.endTime }}
              </p>
            </CCol>

          </CRow>
        </CCol>
      </CCardBody>
    </CModal>

    <CCol col="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'events',self.userId,self.groupId)"
                  ><i class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="
                exports(self, 'Excel', module, 'events',self.userId,self.groupId)
              "
          >
            <template #isFree="data">
              <CBadge :color="statusBudget(data.row.isFree)">{{ data.row.isFree }}</CBadge>
            </template>
            <template #isOnline="data">
              <CBadge :color="statusBudget(data.row.isOnline)">{{ data.row.isOnline }}</CBadge>
            </template>
            <template #actions="data">
              <CButton
                  color="primary"
                  v-on:click="viewComments(data.row.id,data.row.title)"
              >
                <i class="fas fa-comment"></i>
              </CButton>
              <CButton color="primary" v-on:click="viewEvents(data.row.id)">
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton color="primary" v-on:click="viewReports(data.row.id,data.row.title)">
                <i class="fas fa-comment"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "EventTab",
  mixins: [Mixin],
  components: {
    VueJsonToCsv
  },
  data() {
    return {
      groupId: '',
      userId: '',
      submitted: false,
      listUrl: '/users/groups/events/list',
      json_data: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      event: {
        title: '',
        description: '',
        isFree: '',
        isOnline: '',
        startTime: '',
        endTime: '',
        link: '',
        location: '',
        participants: '',
      },
      maxlength: {
        // category: Maxlength.gac.name,
      },
      columns: ["title", "isFree", "isOnline", "startTime", "endTime", "participants", 'createdAt', 'updatedAt', "actions"],
      data: [],
      options: {
        headings: {
          title: this.$lang.events.table.title,
          isFree: this.$lang.events.table.isFree,
          isOnline: this.$lang.events.table.isOnline,
          startTime: this.$lang.events.table.startTime,
          endTime: this.$lang.events.table.endTime,
          participants: this.$lang.events.table.participants,
          createdAt: this.$lang.events.table.createdAt,
          updatedAt: this.$lang.events.table.updatedAt,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name"],
        sortable: [],
        filterable: ["title"],
        // see the options API
      },
    };
  },
  validations: {
    gac: {
      name: {
        required,
      },
    },
  },

  created() {

    let self = this;
    self.dismissCountDown = 0;
    if (this.$route.params.group_id) {
      self.groupId = this.$route.params.group_id;
      self.userId = this.$route.params.user_id;
      self.dismissCountDown = 0;
      self.listUrl = self.listUrl + "/" + this.$route.params.group_id + "/" + this.$route.params.user_id;
    }
    store.commit('showLoader', false); // Loader Start
  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // self.data = this.loadData();
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.gac.name = "";
      self.gac.countryId = "";
      self.largeModal = true;
      self.gac.status = 1;
    },
    viewComments(eventId, eventName) {
      localStorage.setItem('eventName', eventName);
      this.$router.push({path: `/groups/event/comments/${eventId}`});
    },
    viewReports(eventId, eventName) {
      localStorage.setItem('eventName', eventName);
      this.$router.push({path: `/profile/event/users_reports/${eventId}`});
    },
    viewEvents(eventId) {
      let self = this;
      axios.get('users/groups/events/detail/' + eventId).then((response) => {
        if (response) {
          let responseData = response.data.data;
          self.event.title = responseData.title;
          self.event.description = responseData.description;
          self.event.isFree = responseData.isFree;
          self.event.isOnline = responseData.isOnline;
          self.event.startTime = responseData.startTime;
          self.event.endTime = responseData.endTime;
          self.event.link = responseData.link;
          self.event.location = responseData.location;
          self.event.participants = responseData.participants;
        } else {
          self.data = [];
        }
      });
      self.largeModal = true;
    },

  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
