<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <!--          <strong>{{ $lang.gac.title }}</strong><br>-->
          <strong> Group : {{ groupTitle }} </strong> <br>
          <strong>
            <profiler-detail :username="userName" :profilerId="userId"></profiler-detail>
          </strong>
          <back-to-list></back-to-list>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span @click="selected = 'GAndCategoriesPost'">
              <CTab>
                <template slot="title">
                  {{ $lang.gac.detail.post }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Polls'">
              <CTab>
                <template slot="title">
                  {{ $lang.gac.detail.polls }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Events'">
              <CTab>
                <template slot="title">
                  {{ $lang.gac.detail.events }}
                </template>
              </CTab>
            </span>
            <!--            <span @click="selected = 'GiveAndAskCategoriesPostReport'">-->
            <!--              <CTab>-->
            <!--                <template slot="title">-->
            <!--                  {{ $lang.gac.detail.report }}-->
            <!--                </template>-->
            <!--              </CTab>-->
            <!--            </span>-->
          </CTabs>
          <component
              :is="selected"
              @id="getId($event)"
              :paramId="paramId"
              @changeSelected="getTab($event)"
              style="margin-top: 20px"
          ></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";
import GAndCategoriesPost from "./tab/GAndCategoriesPost";
import Polls from "./tab/Poll";
import BackToList from "../backToList";
import GacChat from "./gacChat";
import ProfilerDetail from "../ProfilerDetail";
import Events from "./tab/Events";

//import GiveAndAskCategoriesPostReport from "./tab/GiveAndAskCategoriesPostReport";
export default {
  name: "GAndADetail",
  mixins: [Mixin],
  components: {
    ProfilerDetail,
    GAndCategoriesPost,
    Polls,
    Events,
    GacChat,
    BackToList
    // GiveAndAskCategoriesPostReport,
  },
  data() {
    return {
      paramId: "",
      userName: '',
      userId: '',
      groupTitle: '',
      tab: "",
      showReport: "",
      tabs: ["GAndCategoriesPost", "Polls", "gacChat", "Events"],
      selected: "GAndCategoriesPost",
    };
  },

  created() {
    let self = this;
    self.userId = localStorage.getItem('userId');
    self.userName = localStorage.getItem('userName');
    self.groupTitle = localStorage.getItem('groupTitle');
  },

  methods: {
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/gac"});
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
      self.showReport = tab !== "GAndCategoriesPost";
    },
    getId(id) {
      let self = this;
      self.paramId = id;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
