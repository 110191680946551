<template>
  <CRow>
    <CCol col="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Polls',self.userId,self.groupId)"
                  ><i class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert :color="messageColor" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}</CAlert>
          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
                          @loaded="exports(self, 'Excel', module, 'Polls', self.userId,self.groupId)">
            <template #actions="data">
              <CButton v-show="data.row.commentsCount" color="primary" v-on:click="viewComments(data.row.id)">
                <i class="fas fa-comment"></i>
              </CButton>
              <CButton v-show="data.row.totalPollResponsesCount" color="primary"
                       v-on:click="viewResponses(data.row.id)">
                <i class="fas fa-user"></i>
              </CButton>
              <CButton v-show="data.row.totalPollReportsCount" color="primary" v-on:click="viewReports(data.row.id)">
                <i class="fas fa-comment"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "PollTab",
  mixins: [Mixin],
  components: {
    VueJsonToCsv
  },
  data() {
    return {
      groupId: '',
      userId: '',
      submitted: false,
      listUrl: '/users/groups/poll/list',
      json_data: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      gac: {},
      maxlength: {
        // category: Maxlength.gac.name,
      },
      columns: ["name", "optionList", "type", "endDate", "commentsCount", "totalPollResponsesCount", "actions"],
      data: [],
      options: {
        headings: {
          name: this.$lang.poll.table.name,
          optionList: this.$lang.poll.table.options,
          type: this.$lang.poll.table.type,
          endDate: this.$lang.poll.table.endDate,
          commentsCount: this.$lang.poll.table.commentsCount,
          totalPollResponsesCount: this.$lang.poll.table.totalResponse,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name"],
        sortable: ["name", "type", "endDate", "commentsCount", "totalPollResponsesCount"],
        filterable: ["name"],
        // see the options API
      },
    };
  },
  validations: {
    gac: {
      name: {
        required,
      },
    },
  },

  created() {

    let self = this;
    self.dismissCountDown = 0;
    if (this.$route.params.group_id) {
      self.groupId = this.$route.params.group_id;
      self.userId = this.$route.params.user_id;
      self.dismissCountDown = 0;
      self.listUrl = self.listUrl + "/" + this.$route.params.group_id + "/" + this.$route.params.user_id;
    }
    store.commit('showLoader', false); // Loader Start
  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // self.data = this.loadData();
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.gac.name = "";
      self.gac.countryId = "";
      self.largeModal = true;
      self.gac.status = 1;
    },
    viewComments(pollId) {
      this.$router.push({path: `/profile/poll/comments/${pollId}`});
    },
    viewResponses(pollId) {
      this.$router.push({path: `/profile/poll/users_responses/${pollId}`});
    },
    viewReports(pollId) {
      this.$router.push({path: `/profile/poll/users_reports/${pollId}`});
    },
    onSubmit() {
      let self = this;
      this.$v.gac.$touch();
      if (this.$v.gac.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            let statusValue = "InActive";
            if (self.gac.status === 1) {
              statusValue = "Active";
            }
            const postData = {
              name: self.gac.name,
              status: statusValue,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enabled Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.messageColor = "success";
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enabled Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            let statusValue = "";
            if (self.gac.status !== "") {
              statusValue = self.gac.status.value;
            }
            const postData = {
              name: self.gac.name,
              status: statusValue,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.gac.country = "";
                    self.$refs.myTable.refresh();
                    self.messageColor = "success";
                    self.submitted = false; //Enabled Button
                  } else {
                    self.alertMessage = "";
                    self.err_msg = response.data.message;
                    self.submitted = false; //Enabled Button
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enabled Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
